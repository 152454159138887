import { Container } from '@chakra-ui/react';
import { deleteCookie } from 'cookies-next';
import { GetServerSidePropsContext, NextPage } from 'next';
import { getSession } from 'next-auth/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { withCommonServerSideProps } from '@/lib/withCommonServerSideProps';

import { H1, H2 } from '@/components/Heading';
import { LoginDisabled } from '@/components/Login/LoginDisabled';
import { LoginForm } from '@/components/Login/LoginForm';
import { RegisterEncourage } from '@/components/Login/RegisterEncourage';
import Seo from '@/components/Seo';

import { CookieKeys } from '@/constants/cookie-keys';
import RoutePath from '@/constants/route-path';
import { Locks } from '@/models/api/Locks';

type LoginPageProps = {
    locks: Locks;
};

const Login: NextPage<LoginPageProps> = (props) => {
    const intl = useIntl();
    const { loginAllowed } = props.locks;

    return (
        <>
            <Seo
                templateTitle={intl.formatMessage({ id: 'page.login.title' })}
                hideBreadCrumbs={!loginAllowed}
            />
            {loginAllowed ? (
                <Container as="section" maxW="3xl" py={4}>
                    <H2
                        as="h2"
                        textAlign="center"
                        fontSize={{
                            base: '20px',
                            lg: '28px'
                        }}
                    >
                        <FormattedMessage id="page.login.register" />
                    </H2>
                    <RegisterEncourage />

                    <H1
                        mt={10}
                        textAlign="center"
                        fontSize={{
                            base: '20px',
                            lg: '28px'
                        }}
                    >
                        <FormattedMessage id="page.login.title"></FormattedMessage>
                    </H1>
                    <LoginForm />
                </Container>
            ) : (
                <LoginDisabled />
            )}
        </>
    );
};

export const getServerSideProps = withCommonServerSideProps(
    async (context: GetServerSidePropsContext) => {
        const { req, res } = context;
        deleteCookie(CookieKeys.showLoginDisabledPopup, { req, res });
        const session = await getSession(context);
        if (session) {
            return {
                redirect: {
                    destination: RoutePath.Profile,
                    permanent: false
                }
            };
        }
        return {
            props: {}
        };
    }
);

export default Login;
