import { ValidationKey } from '@/constants/validation-key';
import { validateMinLength, validatePattern } from '@/validators/common';
import { ValidationReturn } from '@/validators/countryInstanceValidators';

export function validateApartmentNumber(buildingNumber: string): ValidationReturn {
    if (!buildingNumber) {
        return undefined;
    }
    const pattern = new RegExp(
        /^[1-9a-zA-Z][0-9\s/a-zA-Z]{0,25}[/\-,.—a-zA-Z]?[0-9\s]{0,24}$/,
        'g'
    );
    const isValid = pattern.test(buildingNumber);
    return !isValid ? ValidationKey.BuildingNo : undefined;
}

export function validateBuildingNumber(buildingNumber: string): ValidationReturn {
    if (buildingNumber.startsWith('0')) {
        return ValidationKey.BuildingNoCannotStartsWithZero;
    }
    const pattern = new RegExp(/^[1-9][ ,./\u2010\u002D\u2212\u2013\u2014\u2012\w]*$/, 'g');
    const isValid = pattern.test(buildingNumber);
    return !isValid ? ValidationKey.BuildingNo : undefined;
}

export function validateCity(city: string, minLength = 2): ValidationReturn {
    if (!validateMinLength(city, minLength)) {
        return ValidationKey.CityNameTooShort;
    }
    const VALID_CITY_REGEXP =
        /^(?:\d(?=.*\p{L})|\p{L})[,./\u2010\u002D\u2212\u2013\u2014\u2012\p{L}\p{N}\p{Zs}]+$/gu;
    const isValid = validatePattern(city, VALID_CITY_REGEXP);

    return !isValid ? ValidationKey.InvalidCityFormat : undefined;
}

export function validateStreetName(streetName: string, minLength = 2): ValidationReturn {
    if (streetName.length < minLength) {
        return ValidationKey.InvalidStreetName;
    }

    let character;
    let differentCharacters = false;
    for (let i = 0; i < streetName.length; i++) {
        const char = streetName.charAt(i);
        if (i === 0) {
            character = char;
            continue;
        }

        if (char !== character) {
            differentCharacters = true;
            break;
        }
    }

    if (!differentCharacters) {
        return ValidationKey.InvalidStreetName;
    }

    const VALID_STREET_NAME_REGEXP =
        /^(?:\d(?=.*\p{L})|\p{L})[,./\u2010\u002D\u2212\u2013\u2014\u2012\p{L}\p{N}\p{Zs}]+$/gu;

    const isValid = validatePattern(streetName, VALID_STREET_NAME_REGEXP);

    return !isValid ? ValidationKey.InvalidStreetFormat : undefined;
}
