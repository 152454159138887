import { Grid, ListItem, UnorderedList } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';

import { CartIcon, CheckIcon, DartBoardIcon, GiftIcon } from '../Icons';

export const RegisterEncourage = () => {
    return (
        <Grid mt={8} gap={4} justifyContent="center">
            <UnorderedList
                display="flex"
                flexDirection="column"
                gap="10px"
                mb={4}
                pl={6}
                listStyleType="none"
            >
                <ListItem display="flex" gap="2" alignItems="center">
                    <CheckIcon w="16px" />
                    <FormattedMessage id="page.login.encourage.list-item-1" />
                    <CartIcon pointerEvents="none" h="16px" w="auto" />
                </ListItem>
                <ListItem display="flex" gap="2" alignItems="center">
                    <CheckIcon w="16px" />
                    <FormattedMessage id="page.login.encourage.list-item-2" />
                    <GiftIcon pointerEvents="none" h="16px" w="auto" />
                </ListItem>
                <ListItem display="flex" gap="2" alignItems="center">
                    <CheckIcon w="16px" />
                    <FormattedMessage id="page.login.encourage.list-item-3" />
                    <DartBoardIcon pointerEvents="none" h="16px" w="auto" fill="#000000" />
                </ListItem>
            </UnorderedList>
            <NavLink
                width="250px"
                textAlign="center"
                variant="buttonBlue"
                fontWeight="semibold"
                mx="auto"
                href={RoutePath.Register}
                borderRadius={21}
                px={4}
                py={2}
                _hover={{
                    bgColor: 'blue.600'
                }}
            >
                <FormattedMessage id="page.login.create-account" />
            </NavLink>
        </Grid>
    );
};
